// app
const TOGGLE_MENU = "TOGGLE_MENU";

// auth
const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

// expense
const EXPENSES_REQUEST = "EXPENSES_REQUEST";
const EXPENSES_SUCCESS = "EXPENSES_SUCCESS";
const EXPENSES_FAILURE = "EXPENSES_FAILURE";

const EXPENSE_DETAILS_REQUEST = "EXPENSE_DETAILS_REQUEST";
const EXPENSE_DETAILS_SUCCESS = "EXPENSE_DETAILS_SUCCESS";
const EXPENSE_DETAILS_FAILURE = "EXPENSE_DETAILS_FAILURE";

const EXPENSE_DELETE_REQUEST = "EXPENSE_DELETE_REQUEST";
const EXPENSE_DELETE_SUCCESS = "EXPENSE_DELETE_SUCCESS";
const EXPENSE_DELETE_FAILURE = "EXPENSE_DELETE_FAILURE";

const EXPENSE_UPDATE_REQUEST = "EXPENSE_UPDATE_REQUEST";
const EXPENSE_UPDATE_SUCCESS = "EXPENSE_UPDATE_SUCCESS";
const EXPENSE_UPDATE_FAILURE = "EXPENSE_UPDATE_FAILURE";

const EXPENSE_FILTER_CHANGE = "EXPENSE_FILTER_CHANGE";
const EXPENSE_FILTER_CLEAR = "EXPENSE_FILTER_CLEAR";

// category
const CATEGORIES_REQUEST = "CATEGORIES_REQUEST";
const CATEGORIES_SUCCESS = "CATEGORIES_SUCCESS";
const CATEGORIES_FAILURE = "CATEGORIES_FAILURE";

const CATEGORY_DETAILS_REQUEST = "CATEGORY_DETAILS_REQUEST";
const CATEGORY_DETAILS_SUCCESS = "CATEGORY_DETAILS_SUCCESS";
const CATEGORY_DETAILS_FAILURE = "CATEGORY_DETAILS_FAILURE";

const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
const CATEGORY_DELETE_FAILURE = "CATEGORY_DELETE_FAILURE";

const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
const CATEGORY_UPDATE_FAILURE = "CATEGORY_UPDATE_FAILURE";

const actionsTypes = {
  TOGGLE_MENU,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  EXPENSES_REQUEST,
  EXPENSES_SUCCESS,
  EXPENSES_FAILURE,
  EXPENSE_DETAILS_REQUEST,
  EXPENSE_DETAILS_SUCCESS,
  EXPENSE_DETAILS_FAILURE,
  EXPENSE_DELETE_REQUEST,
  EXPENSE_DELETE_SUCCESS,
  EXPENSE_DELETE_FAILURE,
  EXPENSE_UPDATE_REQUEST,
  EXPENSE_UPDATE_SUCCESS,
  EXPENSE_UPDATE_FAILURE,
  EXPENSE_FILTER_CHANGE,
  EXPENSE_FILTER_CLEAR,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAILURE,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAILURE,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAILURE,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAILURE
};

export default actionsTypes;
